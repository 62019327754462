import { mapGetters } from 'vuex';
import { ORIGINAL_FLOW } from './consts';

export default {
  computed: {
    ...mapGetters(['userProfileWizardAbTest']),
    isOriginalFlow() {
      return this.userProfileWizardAbTest === ORIGINAL_FLOW;
    },
  },
};
